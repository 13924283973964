@font-face {
    font-family: "Apple SD Gothic Neo";
    src: url("../fonts/AppleSDGothicNeoM.ttf");
}

* {
    font-family: "Apple SD Gothic Neo", sans-serif !important;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: #657493;
}